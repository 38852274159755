<template>
  <div class="main">
    <v-card :loading="loading" :disabled="disabled" id="registerCard">
      <div class="form">
        <h1 class="title">SignUp</h1>
        <form @submit.prevent="pressed">
          <div class="inputs">
            <div id="input">
              <v-text-field
                label="Email"
                v-model="email"
                type="Email"
                outlined
                required
                dense
                class="mb-n3"
              ></v-text-field>
            </div>

            <div id="input">
              <v-text-field
                label="Password"
                v-model="password"
                type="Password"
                :error-messages="errors"
                outlined
                dense
                class="mb-n3"
                required
              ></v-text-field>
            </div>
            <div id="input">
              <v-text-field
                label="Confirm Password"
                v-model="confirmPassword"
                type="Password"
                :error-messages="errors"
                outlined
                dense
                class="mb-n3"
                required
              ></v-text-field>
            </div>
          </div>
          <v-btn color="primary" class="white--text" type="submit">
            SignUp
            <v-icon right dark> mdi-arrow-right-circle </v-icon>
          </v-btn>
          <div justify="center">
            <small>
              Have an account? <router-link to="/login">Login</router-link>
            </small>
          </div>
          <v-snackbar :timeout="4000" v-model="snackbar" absolute bottom center>
            {{ snackbarText }}
          </v-snackbar>
        </form>
      </div>
      <div class="card-img">
        <lottie-player
          src="https://assets4.lottiefiles.com/packages/lf20_gjmecwii.json"
          loop
          background="transparent"
          speed="1"
          style="width: 200px; height: 200px"
          autoplay
        ></lottie-player>
      </div>
    </v-card>

    <!--otp dialogue -->
    <v-dialog v-model="otpDialogue" width="320px" persistent>
      <v-card :loading="otpDialogueLAD" :disabled="otpDialogueLAD">
        <v-card-title>
          <span class="headline">One Time Password</span>
        </v-card-title>
        <v-card-text>
          <form id="otpConfirmForm" v-if="!otpSent">
            <p>
              OTP will be sent to {{ email }} <br />
              wrong email?
              <span
                color="primary"
                text
                @click="changeEmail"
                style="cursor: pointer; color: rgb(45, 77, 218)"
                >Change</span
              >
            </p>
            <v-btn color="primary" class="white--text" @click="sendOtpNow()"
              >SEND OTP</v-btn
            >
            <v-btn
              color="red"
              class="white--text ml-2"
              text
              form="otpConfirmForm"
              @click="closeOtpDialogue"
              >Cancel</v-btn
            >
          </form>

          <!-- enter otp -->
          <form @submit.prevent="verifyOtpNow" id="otpForm" class="mt-0" v-else>
            <p>
              OTP have been sent to <br />{{ email }} <br />
              wrong email?
              <span
                color="primary"
                text
                @click="changeEmail"
                style="cursor: pointer; color: rgb(45, 77, 218)"
                >Change</span
              >
            </p>
            <div class="input-row">
              <v-text-field
                :rules="[rules.required]"
                dense
                placeholder="Enter OTP"
                outlined
                color="primary"
                v-model="otp"
                type="number"
                pattern="[0-9]{6}"
                required
                maxlength="6"
              ></v-text-field>
              <div class="input-col">
                <h3 class="grey--text mb-6 ml-2">{{ timeLeft }}</h3>
              </div>
            </div>
            <v-row class="ml-1 mb-2 mt-0">
              <v-btn
                color="primary"
                class="text--white"
                type="submit"
                form="otpForm"
                >VERIFY</v-btn
              >
              <v-btn
                color="#535353"
                class="text--white ml-2"
                text
                @click="resendOtp"
                :disabled="timerOn"
                >Resend</v-btn
              >
            </v-row>
          </form>
          <small v-if="timerOn">You can resend otp after {{ timeLeft }}</small>
          <v-banner
            color="red"
            class="white--text rounded mt-2"
            v-if="otpError"
          >
            {{ otpErrorMessage }}
          </v-banner>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { authService } from "../../../services";
import Joi from "@hapi/joi";

export default {
  name: "signups",
  layout: "authlay",
  data() {
    return {
      //rules
      rules: {
        required: (value) => !!value || "Required.",
      },

      //snackbar
      snackbar: false,
      snackbarText: "No error message",

      //fields
      email: "",
      password: "",
      confirmPassword: "",

      //errors
      errors: "",

      //card
      loading: false,
      disabled: false,

      //otp
      otpSent: false,
      otpDialogue: false,
      otpDialogueLAD: false,
      otp: null,
      otpError: false,
      otpErrorMessage: null,
      timerInterval: null,
      timeLeft: null,
      timer: 90,
      timerOn: false,
    };
  },

  methods: {
    ...mapActions(["signInWithEmail", "fetchProfile"]),

    async pressed() {
      const data = {
        email: this.email,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };

      const registerRequestSchema = Joi.object({
        email: Joi.string().email().lowercase().required(),
        password: Joi.string().min(7).required().strict(),
        confirmPassword: Joi.string()
          .valid(Joi.ref("password"))
          .required()
          .strict(),
      });

      const vResult = registerRequestSchema.validate(data);
      if (vResult.error) {
        this.snackbar = true;
        this.snackbarText = vResult.error.details[0].message;
        console.log(vResult.error.details);
      } else {
        // await authService.registerRequest(data.email, data.password);
        // this.otpDialogue = true;
        this.sendOtpConfirm();
      }
    },

    //otp
    sendOtpConfirm() {
      this.otpSent = false;
      this.otpError = false;
      this.otpDialogue = true;
    },
    async sendOtpNow() {
      // this.otpSent = false;
      console.log("send otp now");
      this.otpError = false;
      this.otpDialogueLAD = true;

      const response = await authService.registerRequest(
        this.email,
        this.password
      );
      console.log(response);

      if (response.success) {
        this.otpSent = true;
        this.startTimer();
      } else {
        if (response.code == 0) {
          this.otpError = true;
          this.snackbar = true;
          this.snackbar = "Unable to send OTP to " + this.email;
        }
      }
      this.otpDialogueLAD = false;
    },
    async verifyOtpNow() {
      this.otpDialogueLAD = true;
      const response = await authService.register(
        this.email,
        parseInt(this.otp)
      );
      console.log(response);
      if (response.success) {
        this.otpDialogue = false;
        this.otpSent = false;
        this.stopTimer();

        const LoginResult = await this.signInWithEmail({
          email: this.email,
          password: this.password,
        });

        if (LoginResult.user) {
          this.snackbarText = "Registered Successfully";
          this.snackbar = true;
          await this.fetchProfile();
          this.$router.push("/myaccount");
          this.loading = false;
          this.disabled = false;
        }
        this.snackbarText = "Register Unsuccessfull";
        this.snackbar = true;
      } else {
        this.otpError = true;
        if (response.message == "OTP Expired")
          this.otpErrorMessage = "OTP Expired";
        else this.otpErrorMessage = "Enter correct OTP";
      }
      this.otpDialogueLAD = false;
    },
    async resendOtp() {
      this.otpError = false;
      this.stopTimer();
      this.sendOtpNow();
      console.log("resend otp");
    },
    async changeEmail() {
      this.stopTimer();
      this.otpSent = false;
      this.otpDialogue = false;
      console.log("Change Email");
    },
    startTimer() {
      this.timerOn = true;
      this.timer = 90;
      this.timerInterval = setInterval(() => {
        if (this.timer > 0) this.timer--;
        else {
          this.timerOn = false;
          clearInterval(this.myInterval);
        }

        let minutes = parseInt(this.timer / 60, 10);
        let seconds = parseInt(this.timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        this.timeLeft = minutes + ":" + seconds;
        console.log(this.timeLeft);
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.timerInterval);
      console.log("timer stopped");
    },
    closeOtpDialogue() {
      this.stopTimer();
      this.otpSent = false;
      this.otpDialogue = false;
    },
  },
};
</script>

<style lang='scss' scoped>
.main {
  background-color: #fcf5ff;
  width: 100vw;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#registerCard {
  display: flex;
  justify-content: space-between;
  border-radius: 18px;
  max-width: 580px;
  height: 80vh;

  width: 70%;
  min-width: 300px;
}
.title {
  font-size: 18px;
  font-weight: bold;
  color: rgb(69, 15, 94);
  margin-bottom: 10px;
}

.form {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-img {
  border-radius: 18px;
  background: -webkit-linear-gradient(
    45deg,
    rgb(255, 116, 116),
    rgb(221, 68, 221)
  );
  width: 50%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
v-btn {
  background-color: green;
}
@media (max-width: 780px) {
  .main {
    width: 100vw;
    margin: 0px !important;
  }
  #registerCard {
    height: auto;
    padding: 5px 0px;
    width: 90%;
    display: flex;
    margin: 0px;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
  }
  .form {
    width: 100%;
  }
  .title {
    margin: 0px;
    font-size: 14px;
  }
  .card-img {
    height: 200px;
    // display: none;
  }
}
</style>
